// //Connect Testimonials Carousel
// if (document.getElementById('connect-testimonials-carousel')) {
//     document.addEventListener('DOMContentLoaded', function () {
//         import('@splidejs/splide').then((Splide) => {
//             new Splide.default('#connect-testimonials-carousel', {
//                 type: 'fade',
//                 autoplay: true,
//                 pagination: false,
//             }).mount();
//         });
//     });
// }

// contact sales form
(function () {
    const contactSalesForm = document.getElementById('contactSalesForm');
    if (contactSalesForm) {
        contactSalesForm.addEventListener('submit', function (e) {
            e.preventDefault();
            const name = this.elements['name'].value;
            const phone = this.elements['phone'].value;
            const email = this.elements['email'].value;
            const recaptcha = this.elements['recaptcha'].value;
            const formMessage = document.querySelector('.form-message');
            formMessage.style.display = 'none';
            formMessage.classList.remove('error');
            axios
                .post('/api/authland-connect', {
                    name,
                    phone,
                    email,
                    recaptcha,
                })
                .then((response) => {
                    // TODO Add a GA event
                    formMessage.innerText = formMessage.dataset.success;
                })
                .catch((err) => {
                    const { data } = err.response;
                    formMessage.innerText = data?.message ? data.message : formMessage.dataset.error;
                    formMessage.classList.add('error');
                })
                .finally(() => (formMessage.style.display = 'block'));
        });
    }
})();

//Scroll to contact form on connect landing page
(function () {
    document.querySelectorAll('a[href^="#newsletter"]').forEach((anchor) => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            const targetId = this.getAttribute('href').substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                const navbarElement = document.getElementById('navbar');

                if (navbarElement) {
                    // Get the computed height of the navbar
                    const navbarHeight = navbarElement.offsetHeight;
                    // Calculate the offset from the top of the page to the target element
                    const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
                    window.scrollTo({
                        top: targetPosition - navbarHeight,
                        behavior: 'smooth',
                    });
                }
            }
        });
    });
})();

/*Authland Connect Landing Page Fade In Effect*/
function handleIntersection(entries, observer) {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target);
        }
    });
}

const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
};

const intersectionObserver = new IntersectionObserver(handleIntersection, observerOptions);
const elements = document.querySelectorAll('.animate-on-scroll');

elements.forEach((el) => {
    intersectionObserver.observe(el);
});
